/** @jsxImportSource @emotion/react */
import { useRef, useState } from "react";
import SEO from "../../../components/old/seo";
import Footer from "../../../components/shared/Footer/Footer";
import PrevNextCaseStudy from "../../../components/shared/PrevNextCaseStudy/PrevNextCaseStudy";
import { PageProps } from "../../../components/utils/PageProps";
import CardCarousel from "./components/CardsCarousel/CardCarousel";
import ComplicatedUniverse from "./components/ComplicatedUniverse/ComplicatedUniverse";
import EvolutionDevelopment from "./components/EvolutionDevelopment/EvolutionDevelopment";
import Header from "./components/Header/Header";
import Puzzle from "./components/Puzzle section/Puzzle";
import Quotes from "./components/Quotes section/Quotes";
import Results from "./components/Results/Results";
import ScrollingImages from "./components/ScrollingImages/ScrollingImages";
import WorkshopSlider from "./components/WorkshopSlider/WorkshopSlider";
import { l_isatra } from "./locales";

const Isatra = (props: PageProps) => {
  return (
    <>
      <SEO
        title={l_isatra("seo.title")}
        description={l_isatra("seo.description")}
        image="/case_studies_png/isatra.png"
      />

      <>
        <Header />
        <ComplicatedUniverse />
        <ScrollingImages />
        <WorkshopSlider />
        <CardCarousel />
        <Puzzle />
        <Quotes />
        <EvolutionDevelopment />
        <Results />
        <PrevNextCaseStudy />
        <Footer />
      </>
    </>
  );
};

export default Isatra;
