import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { animated, useSpring } from "react-spring";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const Card = ({
  index,
  leftMargin,
  title,
  descriptionA,
  descriptionB,
  image,
  isClosed,
  openWidth,
  closedWidth,
  openCard,
}: {
  index: number;
  leftMargin: number;
  title: string;
  descriptionA: string;
  descriptionB: string;
  image: any;
  isClosed: boolean;
  openWidth: number;
  closedWidth: number;
  openCard: () => void;
}) => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  const isOverLg = useMediaQuery(theme.breakpoints.up("lg"));

  const AnimatedBox = animated(Box);

  const propsMainBox = useSpring({
    width: `${isClosed ? closedWidth : openWidth}px`,
    flexBasis: `${isClosed ? closedWidth : openWidth}px`,
    config: { tension: 120, mass: 2 },
  });

  const propsTextBox = useSpring({
    opacity: isClosed ? 0 : 1,
    color: isClosed ? "transparent" : "black",
    // transform: isClosed ? "scale(0, 1)" : "scale(1, 1)",
    // transformOrigin: "left",
    config: { duration: 300 },
  });

  return (
    <AnimatedBox
      sx={{
        backgroundColor: "transparent",
        height: openWidth + "px",
        flexShrink: 0,
        boxSizing: "border-box",
      }}
      style={propsMainBox}
      className="carouselCard"
      onClick={() => openCard()}
    >
      <Box
        sx={{
          marginBottom: "24px",
          borderRadius: "40px",
          height: isOverLg ? "420px" : isOverMd ? "330px" : "200px",
          backgroundImage: `url(${image})`,
          backgroundPosition: "50% 50%",
          backgroundSize: isOverLg ? "auto 110%" : "auto 100%",
        }}
      />
      <Box style={{ overflow: "hidden" }}>
        <AnimatedBox
          sx={{
            textOverflow: "hidden",
            // marginLeft: isOverMd ? "0px" : `${-leftMargin}px`,
            width: isOverMd ? `${openWidth}px` : `${openWidth - 8}px`,
            // padding: isOverMd ? 0 : "8px",
          }}
          style={propsTextBox}
        >
          <Typography
            sx={{
              fontSize: "24px",
              lineHeight: "32px",
              fontWeight: 700,
              marginBottom: "8px",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              // marginLeft: isOverMd ? "0px" : `${-leftMargin}px`,
              // textAlign: isOverMd ? "left" : "center",
              fontSize: "14px",
              lineHeight: "18px",
              fontWeight: 400,
            }}
          >
            <span children={descriptionA} />
            <span
              children={" " + descriptionB}
              style={{ opacity: isOverMd ? 1 : 0 }}
            />
          </Typography>
        </AnimatedBox>
      </Box>
    </AnimatedBox>
  );
};

const LockScrollCarousel = ({
  steps,
}: {
  steps: {
    title: string;
    descriptionA: string;
    descriptionB: string;
    image: any;
  }[];
}) => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  const isOverLg = useMediaQuery(theme.breakpoints.up("lg"));

  const [openCard, setOpenCard] = useState(-1);
  const controllerRef = useRef<ScrollTrigger | null>(null);
  const openWidth = isOverLg ? 610 : isOverMd ? 510 : 200;
  const closedWidth = isOverLg ? 68 : isOverMd ? 52 : 24;
  const gap = isOverLg ? 40 : isOverMd ? 32 : 8;

  function scrollToCard(i: number) {
    setOpenCard(i);
    controllerRef.current?.scroll(
      controllerRef.current.start +
        (1 / steps.length) *
          i *
          (controllerRef.current.end - controllerRef.current.start)
    );
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    controllerRef.current?.kill();

    controllerRef.current = ScrollTrigger.create({
      trigger: ".cardCarouselContainer",
      start: isOverMd ? "top 22%" : "top 10%",
      scrub: true,
      pin: true,
      pinSpacing: false,
      onUpdate: (self) => {
        setOpenCard(Math.round(self.progress / (1 / steps.length)));
      },
      onLeave: () => setOpenCard(-1),
      onLeaveBack: () => setOpenCard(-1),
    });
  }, [isOverMd]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        // height: "1500px",
        paddingTop: "100px",
        marginBottom: isOverMd ? "800px" : "400px",
        boxSizing: "border-box",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          gap: { gap },
          boxSizing: "border-box",
          // border: "1px solid red",
          width: "fit-content",
          justifyContent: "center",
          height: "3000px",
          // paddingTop: "100px",
        }}
        className="cardCarouselContainer"
      >
        {steps.map((step, i) => (
          <Card
            key={step.title}
            index={i}
            leftMargin={i * (closedWidth + gap)}
            title={step.title}
            descriptionA={step.descriptionA}
            descriptionB={step.descriptionB}
            image={step.image}
            isClosed={i === openCard ? false : true}
            openWidth={openWidth}
            closedWidth={closedWidth}
            openCard={() => scrollToCard(i)}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default LockScrollCarousel;
